<template>
  <div class="infoWrapperWrap" :class="{ 'opened' : isOpened }">
    <span class="shadowOverlay" @click="toggleVideo()"></span>
    <div v-if="tabs" id="info-wrapper" :class="{ 'opened' : isOpened, 'transition-linear' : 1 }">
        <button class="btn btn-light btn-lg toggle" @click="toggleVideo()">
        <img v-if="this.isOpened" class="rotate-45" src="@/assets/images/arrow-gray.svg" alt="">
        <img v-else src="@/assets/images/Info.svg" alt="">
      </button>
        <swiper class="swiper" ref="scrollCont" :options="scrollInit">
        <swiper-slide>
          <div class="inner" v-if="activeTab">
            <div class="inner-wrap">
              <div class="wrapper-sec">
                <div class="curved-section-top">
                  <div class="inner-curved-section">
                    <h2 v-html="activeTab.headerTitle.replace(/<p[^>]*>/g, '')"></h2>
                  </div>
                </div>
                <div class="tabs-wrapper">
                  <div class="tabs-nav">
                    <swiper ref="probSlider"
                            :class="{ 'customSliderAlign' : tabs.length < 3 }"
                            :options="sliderInit">
                      <swiper-slide v-for="(item, index) in tabs" :class="{ active: item.title === activeTab.title }"
                                    :key="'tab' + index">
                        <span @click.prevent="handleActiveTab(item)">{{ item.title }}</span>
                      </swiper-slide>
                    </swiper>
                    <div v-if="tabs.length > 3" class="swiper-button-prev" slot="button-prev"></div>
                    <div v-if="tabs.length > 3" class="swiper-button-next" slot="button-next"></div>
                  </div>
                  <div class="tabs-content">
                    <!-- <p class="tab-content-subtitle" :key="'subtitle-' + i" v-if="activeTab.subtitle" v-html="activeTab.subtitle"></p> -->
                    <template v-for="(item, i) in activeTab.elements">
                      <div
                        v-if="item._group === 'first_box' || item._group === 'second_box'" class="tab-item"
                        :class="{
                        'pink-item': item._group === 'second_box',
                        'flex-row-reverse': item.align === 'right'
                      }" :key="'item-' + i">
                        <div class="tab-item-image"
                             :class="{ [item.image_vertical_align]: item.image_vertical_align && item._group === 'second_box', ['side-' + item.image_horizontal_align]: item.image_horizontal_align  }">
                          <div class="tab-image-pink">
                            <div class="img-tab" :class="{ 'videoItem': item.video }">
                              <div v-if="item.video">
                                <video width="100%" height="auto" controls="0" autoplay loop
                                       style="pointer-events: none">
                                  <source :src="$store.state.slides.baseUrl + item.video" type="video/mp4">
                                </video>
                              </div>
                              <img v-else-if="item.image" :src="$store.state.slides.baseUrl + item.image"/>
                            </div>
                          </div>
                        </div>
                        <div class="tab-item-content">
                          <h3 class="pink-color" v-html="item.title.replace(/<p[^>]*>/g, '')" v-if="item.title"></h3>
                          <div v-html="item.content"></div>
                          <div class="tab-item-link" v-if="item.link.url">
                            <a :href="item.link.url" :target="item.link.new_tab === 0 ? '_self' : '_blank'">
                              <span class="linkLogo" v-if="!!parseInt(item.link.icon)">
                                <img :src="require('@/assets/images/Dive_Logo_Neu_solo.svg')" height="24px" alt="">
                              </span>
                              <span class="linkArrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="47.999" height="48" viewBox="0 0 47.999 48">
                                  <path fill="#53BAF2" id="Path_253" data-name="Path 253" d="M0,1.125A1.125,1.125,0,0,1,1.921.33L16.875,15.284,31.83.33A1.125,1.125,0,0,1,33.42,1.92L17.67,17.67a1.123,1.123,0,0,1-1.591,0L.33,1.92A1.119,1.119,0,0,1,0,1.125Z" transform="translate(7.125 15)"/>
                                </svg>
                              </span>
                              {{ item.link.name }}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div v-if="item._group === 'default_box'" class="tab-item" :key="'defBox-' + i">
                        <div class="users-section">
                          <div class="users-image">
                            <video v-if="item.video" width="100%" height="auto" controls="0" autoplay loop
                                   style="pointer-events: none">
                              <source :src="$store.state.slides.baseUrl + item.video" type="video/mp4">
                            </video>
                            <img v-else-if="item.image" :src="$store.state.slides.baseUrl + item.image"/>
                          </div>
                          <div class="content-wrap">
                            <h3 v-html="item.content.replace(/<p[^>]*>/g, '')"></h3>
                          </div>
                        </div>
                      </div>
                      <div v-if="item._group === 'footer'" class="tab-item" :key="'footer-' + i">
                        <div class="curved-section-top bottom">
                          <h2 v-html="item.title"></h2>
                          <div class="inner-curved-section">
                            <div class="curved-left">
                              <h3 v-html="item.content.replace(/<p[^>]*>/g, '')"></h3>
                            </div>
                            <div class="curved-right" v-if="item.image || (item.link && item.link.url)">
                              <img v-if="item.image" :src="$store.state.slides.baseUrl + item.image"/>
                              <div class="tab-item-link" v-if="item.link && item.link.url">
                                <a :href="item.link.url" :target="item.link.new_tab === 0 ? '_self' : '_blank'">
                                  <span class="linkLogo" v-if="!!parseInt(item.link.icon)">
                                    <img :src="require('@/assets/images/Dive_Logo_Neu_solo.svg')" height="24px" alt="">
                                  </span>
                                  <span class="linkArrow">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="47.999" height="48" viewBox="0 0 47.999 48">
                                      <path fill="#fff" id="Path_253" data-name="Path 253" d="M0,1.125A1.125,1.125,0,0,1,1.921.33L16.875,15.284,31.83.33A1.125,1.125,0,0,1,33.42,1.92L17.67,17.67a1.123,1.123,0,0,1-1.591,0L.33,1.92A1.119,1.119,0,0,1,0,1.125Z" transform="translate(7.125 15)"/>
                                    </svg>
                                    <!-- <img :src="require('@/assets/images/blue-arrow.svg')" height="24px" alt=""> -->
                                  </span>
                                  {{ item.link.name }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="item._group === 'only_text'" class="tab-item" :key="'only_text-' + i">
                        <p class="tab-content-subtitle" v-html="item.content.replace(/<p[^>]*>/g, '')"></p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-scrollbar" slot="scrollbar"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  props: {
    tabs: {
      default: null,
      type: Array
    },
    isOpened: {
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      activeTab: null,
      footerInfo: {
        footerHeadline: 'Telekom<br /> Mesh-Technologie:',
        footerTitle: 'optimales WLAN im<br /> gesamten Zuhause',
        footerImage: 'devices.png'
      },
      sliderInit: {
        slidesPerView: 3,
        spaceBetween: 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      scrollInit: {
        direction: 'vertical',
        slidesPerView: 'auto',
        freeMode: true,
        scrollbar: {
          el: '.swiper-scrollbar'
        },
        mousewheel: true
      }
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  watch: {
    isOpened (val) {
      if (val) {
        if (this.tabs && this.tabs.length > 0) this.handleActiveTab(this.tabs[0])
        setTimeout(() => {
          this.$refs.probSlider.$swiper.update()
          this.$refs.scrollCont.$swiper.update()
        }, 500)
      } else {
        setTimeout(() => {
          this.$refs.scrollCont.$swiper.slideTo(0, 300, false)
        }, 500)
      }
    },
    activeTab (val) {
      setTimeout(() => {
        this.$refs.scrollCont.$swiper.update()
      }, 500)
    }
  },
  methods: {
    toggleVideo () {
      this.$emit('togglePanel', 'infoWrapper', this.isOpened)
    },
    handleActiveTab (tab) {
      this.activeTab = tab
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.tabs && this.tabs.length > 0) this.handleActiveTab(this.tabs[0])
    })
  }
}
</script>
